<template>
    <component :is="userData === undefined ? 'div' : 'b-card'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="userData === undefined"
        >
            <h4 class="alert-heading">
                Error al obtener los datos del usuario
            </h4>
            <div class="alert-body">
                No se encontró ningún usuario con este ID. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'users'}"
                >Lista de usuarios</b-link>
                para buscar a otro usuario
            </div>
        </b-alert>

        <b-tabs
            v-if="userData"
            pills
        >

            <!-- Tab: Account -->
            <b-tab active>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información</span>
                </template>
                <user-edit-tab-information
                    :user-data="userData"
                    class="mt-2 pt-75"
                />
            </b-tab>

        </b-tabs>
    </component>
</template>

<script>

    import {BAlert, BCard, BLink, BTab, BTabs} from 'bootstrap-vue';
    import {onUnmounted, ref} from '@vue/composition-api';
    import router from '@/router';
    import store from '@/store';
    import userStoreModule from '../userStoreModule';
    import UserEditTabInformation from './UserEditTabInformation.vue';

    export default {
        components: {
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,

            UserEditTabInformation,
        },
        setup() {
            const userData = ref(null);

            const USER_APP_STORE_MODULE_NAME = 'app-user';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });

            store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
            .then(response => {
                if (response.data === 'error'){
                    userData.value = undefined;
                } else {
                    userData.value = {
                        password: '',
                        changePhoto: 0,
                        ...response.data
                    };
                }
            })
            .catch(error => {
                if (error.response.status === 404) {
                    userData.value = undefined;
                }
            });

            return {
                userData,
            }
        },
    }

</script>