<template>
    <div>

        <!-- Media -->
        <b-media class="mb-2">
            <template #aside>
                <b-avatar
                    ref="previewEl"
                    :src="avatarSrc(userData.access.photo)"
                    :text="avatarText(userData.access.fullName)"
                    variant="light-success"
                    size="90px"
                    rounded
                />
            </template>
            <h4 class="mb-1">
                {{ userData.access.fullName }}
            </h4>
            <div class="d-flex flex-wrap">
                <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                >
                    <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        @change="onFileChange"
                    >
                    <span class="d-none d-sm-inline">Actualizar</span>
                    <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                    />
                </b-button>
                <b-button
                    @click="removeAvatar"
                    variant="outline-secondary"
                    class="ml-1"
                >
                    <span class="d-none d-sm-inline">Remover</span>
                    <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                    />
                </b-button>
            </div>
        </b-media>

        <!-- User Info: Input Fields -->
        <b-form>
            <b-row>

                <!-- Field: Names -->
                <b-col
                    cols="12"
                    md="4"
                >
                    <b-form-group
                        label="Nombre(s)"
                        label-for="names"
                    >
                        <b-form-input
                            id="names"
                            v-model="userData.access.names"
                        />
                    </b-form-group>
                </b-col>

                <!-- Field: Surnames -->
                <b-col
                    cols="12"
                    md="4"
                >
                    <b-form-group
                        label="Apellido(s)"
                        label-for="surnames"
                    >
                        <b-form-input
                            id="surnames"
                            v-model="userData.access.surnames"
                        />
                    </b-form-group>
                </b-col>

                <!-- Field: Document Number -->
                <b-col
                    cols="12"
                    md="4"
                >
                    <b-form-group
                        label="Número de documento"
                        label-for="documentNumber"
                    >
                        <b-form-input
                            id="documentNumber"
                            v-model="userData.documentNumber"
                            type="text"
                        />
                    </b-form-group>
                </b-col>

            </b-row>

            <b-row>

                <!-- Field: Email -->
                <b-col
                    cols="12"
                    md="4"
                >
                    <b-form-group
                        label="Correo electrónico"
                        label-for="email"
                    >
                        <b-form-input
                            id="email"
                            v-model="userData.access.email"
                            type="email"
                        />
                    </b-form-group>
                </b-col>

                <!-- Field: Phone -->
                <b-col
                    cols="12"
                    md="4"
                >
                    <b-form-group
                        label="Teléfono"
                        label-for="phone"
                    >
                        <b-form-input
                            id="phone"
                            v-model="userData.access.phone"
                            type="text"
                        />
                    </b-form-group>
                </b-col>

                <!-- Field: Customer -->
                <b-col
                    cols="12"
                    md="4"
                >
                    <b-form-group
                        label="Seleccionar cliente"
                        label-for="customerId"
                    >
                        <v-select
                            id="customerId"
                            v-model="userData.customer"
                            label="name"
                            :options="customers"
                        />
                    </b-form-group>
                </b-col>

            </b-row>

            <b-row>

                <!-- Field: Position -->
                <b-col
                    cols="12"
                    md="4"
                >
                    <b-form-group
                        label="Cargo"
                        label-for="position"
                    >
                        <b-form-input
                            id="position"
                            v-model="userData.position"
                        />
                    </b-form-group>
                </b-col>

                <!-- Field: Password -->
                <b-col
                    cols="12"
                    md="8"
                >
                    <b-form-group
                        label="Contraseña (en caso desee actualizarla, complete el siguiente campo, sino, dejar vacío)"
                        label-for="password"
                    >
                        <b-form-input
                            id="password"
                            v-model="userData.password"
                            placeholder="********"
                            autocomplete="new-password"
                        />
                    </b-form-group>
                </b-col>

            </b-row>

        </b-form>

        <!-- Action Buttons -->
        <b-button
            @click="updateInformation"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
            Guardar cambios
        </b-button>
    </div>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea } from 'bootstrap-vue';
    import { avatarText } from '@core/utils/filter';
    import vSelect from 'vue-select';
    import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
    import { ref } from '@vue/composition-api';
    import store from '@/store';
    import axios from 'axios';

    export default {
        components: {
            BButton,
            BMedia,
            BAvatar,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BForm,
            vSelect,
        },
        props: {
            userData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                customers: []
            }
        },
        mounted (){
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/customers/select`)
            .then(response => {
                this.customers = response.data;
            });
        },
        methods: {
            avatarSrc (img){
                if (img){
                    if (img.includes('http')){
                        return img;
                    } else {
                        if (process.env.VUE_APP_ENVIROMENT == 'development'){
                            return require(`@/assets/images/modules/users/${img}`);
                        } else {
                            return `${process.env.VUE_APP_FILES}/modules/users/${img}`;
                        }
                    }
                } else {
                    return null;
                }
            }
        },
        setup(props) {

            // Init Photo
            const initPhoto = props.userData.access.photo;

            // Use toast
            const toast = useToast();

            const blankData = {
                photo: null,
                initPhoto
            };

            // ? Demo Purpose => Update image on click of update
            const refInputEl = ref(null);
            const previewEl = ref(null);

            const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
                // eslint-disable-next-line no-param-reassign
                props.userData.access.photo = base64;
            });

            const onFileChange = (e) => {
                const file = e.target.files[0];
                props.userData.changePhoto = 1;
                if (file) {
                    props.userData.access.photo = URL.createObjectURL(file);
                    blankData.photo = file;
                } else {
                    props.userData.access.photo = null;
                    blankData.photo = null;
                }
            };

            const updateInformation = () => {
                let userData = {
                    ...props.userData,
                    ...blankData
                };
                store.dispatch('app-user/updateUser', { id: props.userData.id, userData })
                .then( (response) => {

                    props.userData.password = '';
                    props.userData.access.fullName = `${response.data.user.names} ${response.data.user.surnames}`;
                    props.userData.access.photo = response.data.photo;
                    blankData.initPhoto = response.data.photo;
                    props.userData.changePhoto = 0;

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });
                })
                .catch( (err) => {
                    let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del usuario';
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });
                });
            };

            const removeAvatar = () => {
                props.userData.access.photo = null;
                blankData.photo = null;
                props.userData.changePhoto = 1;
            };

            return {
                avatarText,
                updateInformation,
                removeAvatar,
                onFileChange,

                //  ? Demo - Update Image on click of update button
                refInputEl,
                previewEl,
                inputImageRenderer,
            }
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
